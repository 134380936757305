@font-face {
  font-family: FlamaWeb;
  font-weight: 300;
  font-style: normal;
  src: url('assets/fonts/Flama-Light.woff2') format('woff2');
}

@font-face {
  font-family: FlamaWeb;
  font-weight: 300;
  font-style: italic;
  src: url('assets/fonts/Flama-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: FlamaWeb;
  font-weight: normal;
  font-style: normal;
  src: url('assets/fonts/Flama-Basic.woff2') format('woff2');
}

@font-face {
  font-family: FlamaWeb;
  font-weight: normal;
  font-style: italic;
  src: url('assets/fonts/Flama-BasicItalic.woff2') format('woff2');
}

@font-face {
  font-family: FlamaWeb;
  font-weight: bold;
  font-style: normal;
  src: url('assets/fonts/Flama-Medium.woff2') format('woff2');
}

@font-face {
  font-family: FlamaWeb;
  font-weight: bold;
  font-style: italic;
  src: url('assets/fonts/Flama-MediumItalic.woff2') format('woff2');
}
